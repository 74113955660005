import { TmapApp } from '@tmap-web-lib/tmap-app-interface'
import Router from 'next/router'
import { UAParser } from 'ua-parser-js'

import { LOCAL_STORAGE_KEY } from '@/constants/key'

import { isClient } from '../compare'
import Logger from '../logger'

export enum METHOD {
  GET = 'GET',
  POST = 'POST',
  PUT = 'PUT',
  DELETE = 'DELETE',
  PATCH = 'PATCH',
}

export const defaultUserwebHeaders: Record<string, string> = {}

export const getDefaultUserwebHeaders = () => {
  return { ...defaultUserwebHeaders }
}

export const defaultFrontmanHeaders: Record<string, string> = {
  'App-Code': 'tmap',
  Using: 'WEB_CS',
}

export const defaultZendeskHeaders: Record<string, string> = {}
export const defaultZendeskAuthHeaders: Record<string, string> = {}

export const getDefaultFrontmanHeaders = async () => {
  const ua = UAParser()
  const [deviceId, carrierName, euk, sessionId] = await Promise.all([
    TmapApp.getDeviceId(),
    TmapApp.getCarrierName(),
    TmapApp.getEUK(),
    TmapApp.getSessionId(),
  ])
  // 통신사 이름이 2바이트 문자열(일본어, 중국어)인 경우 브라우저에서 API 에러 발생하여 영문, 숫자를 제외한 나머지는 ETC로 변경(TMAPWEB-1583)
  const reg = /^[a-zA-Z\d]*$/
  const carrier = carrierName && reg.test(carrierName) ? carrierName : 'ETC'

  return {
    ...defaultFrontmanHeaders,
    Carrier: carrier,
    'Device-Id': deviceId ?? '',
    'Os-Type': TmapApp.env.isAndroid ? 'AND' : 'IOS',
    'App-Version': TmapApp.env.appVersion,
    'Os-Version': ua.os.version || '',
    'Model-No': ua.device.model || '',
    'App-Session-Id': sessionId,
    Euk: euk ?? '',
  }
}

export const getDefaultZendeskHeaders = () => {
  return { ...defaultZendeskHeaders }
}
export const getDefaultZendeskAuthHeaders = () => {
  return { ...defaultZendeskAuthHeaders }
}

export const getAccessKey = async () => {
  if (isClient) {
    const query = new URLSearchParams(window.location.search)
    const ak =
      query.get('ak') ||
      localStorage.getItem(LOCAL_STORAGE_KEY.ACCESS_KEY) ||
      (await TmapApp.getAccessKey())

    if (ak) {
      setAccessKey(ak)
    }

    return ak
  }
  return null
}

export const getEuk = async () => {
  if (isClient) {
    const euk = await TmapApp.getEUK()

    if (euk) {
      setEuk(euk)
    }

    return euk
  }

  return null
}

export const setAccessKey = (ak: string) => {
  defaultFrontmanHeaders.AccessKey = ak
  localStorage.setItem(LOCAL_STORAGE_KEY.ACCESS_KEY, ak)
}

export const setNewAccessKey = (ak: string) => {
  if (isClient) {
    const baseUrl = `${window.location.origin}${window.location.pathname}`
    try {
      Router.replace({
        pathname: baseUrl,
        query: {
          ...Router.query,
          ak,
        },
      })
      TmapApp.updateAccessKey({ key: ak })
      setAccessKey(ak)
    } catch (e) {
      Logger.error(e)
    }
  }
}

export const setEuk = (euk: string) => {
  defaultFrontmanHeaders.euk = euk
}
