import { METHOD, frontmanZendeskApi } from '@/utils/api'

export type PostAuthUserResponse = {
  email: string
  external_id: string
  id: number
  name: string
  verified: boolean
}

export const postAuthUser = (accessKey: string) => {
  return frontmanZendeskApi<PostAuthUserResponse>({
    path: 'api/auth/user',
    method: METHOD.POST,
    headers: {
      accessKey,
    },
  })
}
