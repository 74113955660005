import { Dictionary } from '@tmap-web-lib/tmap-app-interface/dist/types/internal-types'

export const getSchemeJsonData = (search: string): Dictionary<string> | null => {
  if (!search) {
    return null
  }

  return getQueryString(search.split('?')[1] || '')
}

export const getQueryString = (queryString: string) => {
  if (queryString === '') return null
  return queryString.split('&').reduce((map: { [index: string]: string }, part) => {
    const record = part.split('=')
    map[decodeURIComponent(record[0])] = decodeURIComponent(record[1] || '')
    return map as Dictionary<string>
  }, {})
}

export const getDecodeJsonObject = (str: string): Record<string, any> => {
  try {
    return JSON.parse(str.length ? decodeURIComponent(str) : '{}')
  } catch {
    return {}
  }
}

export const getEncodeJsonObject = (obj: Record<string, any>): string => {
  return encodeURIComponent(JSON.stringify(obj))
}

export const parseLinkUrl = ({ url }: { url: string }) => {
  return (
    url
      // 1. "&amp;"를 "&"로 변환(URL에 해당 값이 들어가는 경우를 방지)
      .replace(/&amp;/g, '&')
      // 2. 브릿지 페이지 스킴링크를 인앱에서 바로 띄우기 위해 기존 링크를 비활성화하고 openBridgeLink 함수를 호출한다.
      // FIXME: double quote와 single quote 두 가지 케이스를 모두 대응하고자 정규식 replace를 두 번 실행하고있음.
      // (?:"|') 와 같은 조건으로 잡을 시 정상적인 값으로 replace되지 않는 문제가 존재...
      .replace(
        /href="https:\/\/www.tmap.co.kr\/tmap2\/mobile\/tmap.jsp\?scheme=(.+?)&host=(.+?)">/g,
        'draggable="false" href="$1://$2" onclick="openBridgeLink(this);return false;" >'
      )
      .replace(
        /href='https:\/\/www.tmap.co.kr\/tmap2\/mobile\/tmap.jsp\?scheme=(.+?)&host=(.+?)'>/g,
        "draggable='false' href='$1://$2' onclick='openBridgeLink(this);return false;'>"
      )
      // 3. 외부 브라우저로 띄우기 위해 기존 링크 동작을 비활성화하고 openBrowserLink 함수를 호출한다.
      .replace(
        /href="((http|https).+?)">/g,
        'draggable="false" href="$1" onclick="openBrowserLink(this);return false;" >'
      )
      .replace(
        /href='((http|https).+?)'>/g,
        "draggable='false' href='$1' onclick='openBrowserLink(this);return false;'>"
      )
  )
}

export const parseBridgeToSchemeURL = (url: string) => {
  return url
    .replace(
      /https:\/\/www.tmap.co.kr\/tmap2\/mobile\/tmap.jsp\?scheme=(.+?)&host=(.+?)/g,
      '$1://$2'
    )
    .replace(/tmap:\/\/(.+?)&(.+?)/g, 'tmap://$1?$2')
}

export const isJsonString = (str: string) => {
  try {
    JSON.parse(str)
  } catch {
    return false
  }
  return true
}
