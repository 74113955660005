import { AxiosError } from 'axios'

import { postAuthUser } from '@/apis/frontman/auth/postAuthUser'
import { API, SQUARE_MOCKUP } from '@/constants/api'
import { LOCAL_STORAGE_KEY } from '@/constants/key'

import {
  METHOD,
  getAccessKey,
  getDefaultFrontmanHeaders, // getDefaultZendeskAuthHeaders,
  // getDefaultZendeskHeaders,
  // setZendeskAccessToken,
} from './config'
import { RequestCustomOption, request } from './request'

type FrontmanAPI = {
  method: METHOD
  isProxy?: boolean
  path: string
  query?: Record<string, any>
  headers?: Record<string, string>
  body?: any
  signal?: AbortSignal
  timeout?: number
  useAuth?: boolean
} & RequestCustomOption

const getUrl = (path: string, query?: Record<string, any>) => {
  const pathString = path[0] === '/' ? path : `/${path}`
  const params = new URLSearchParams(query)
  const queryString = params.toString()

  return `${API.ENDPOINT.FRONTMAN}${pathString}${queryString}`
}

export const frontmanApi = async <T = any>(args: FrontmanAPI) => {
  const headers = args.headers || {}
  const defaultFrontmanHeaders = await getDefaultFrontmanHeaders()

  return request<T>(
    {
      method: args.method,
      url: getUrl(args.isProxy ? `/proxy${args.path}` : args.path),
      params: args.query,
      headers: {
        ...defaultFrontmanHeaders,
        ...headers,
      },
      data: args.body,
      signal: args.signal,
      timeout: args.timeout,
    },
    {
      brand: args.brand,
      isErrorActionDisabled: args.isErrorActionDisabled,
    }
  )
}

export const frontmanZendeskApi = async <T = any>(args: FrontmanAPI) => {
  try {
    const headers = args.headers || {}
    const defaultFrontmanHeaders = await getDefaultFrontmanHeaders()

    // MOCKUP MODE
    if (
      localStorage.getItem(LOCAL_STORAGE_KEY.SQUARE_MOCKUP_API_MODE) === 'true' &&
      SQUARE_MOCKUP[args.method][args.path]
    ) {
      return request<T>(
        {
          method: args.method,
          url: `${API.ENDPOINT.SQUARE_MOCKUP}${SQUARE_MOCKUP[args.method][args.path]}`,
          params: args.query,
          headers: {
            ...defaultFrontmanHeaders,
            ...headers,
          },
          data: args.body,
          signal: args.signal,
          timeout: args.timeout,
        },
        {
          brand: args.brand,
          isErrorActionDisabled: args.isErrorActionDisabled,
        }
      )
    }

    const path = args.path[0] === '/' ? `/zendesk/v2${args.path}` : `/zendesk/v2/${args.path}`

    if (args.useAuth && localStorage.getItem(LOCAL_STORAGE_KEY.IS_ZENDESK_AUTH_USER) !== 'true') {
      const ak = localStorage.getItem(LOCAL_STORAGE_KEY.ACCESS_KEY) || (await getAccessKey())
      if (ak) {
        const { name } = await postAuthUser(ak)
        localStorage.setItem(LOCAL_STORAGE_KEY.IS_ZENDESK_AUTH_USER, 'true')
        localStorage.setItem(LOCAL_STORAGE_KEY.TMAP_USER_KEY, name)
      } else {
        throw new AxiosError('Access Key is not found')
      }
    }

    return frontmanApi<T>({
      ...args,
      path,
      headers: {
        ...defaultFrontmanHeaders,
        ...headers,
        brand: args.brand ?? 'tmaphelp',
      },
    })
  } catch (err) {
    return Promise.reject(err)
  }
}
